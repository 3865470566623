<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import PedidoService from "@/services/PedidoService";
const itemService = new PedidoService();

import ZonaService from "@/services/ZonaService";
const zonaService = new ZonaService();

import HelperJS from "@/functions/general";
const helper = new HelperJS();
import 'vue2-datepicker/locale/es';
import moment from 'moment';

/**
 * Invoice-list component
 */
const nameSeccion = 'Pedido';
const namePlural = nameSeccion+'s';
const routeParentName = 'pedidos';
export default {
  page: {
    title: "Listado de "+ namePlural,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker },
  data() {
    return {
      title: namePlural,
      items: [
        {
          text: namePlural,
        },
        {
          text: "Listado de "+ namePlural,
          active: true,
        },
      ],
      invoicelist: [      
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: {
        name: null,
        payment_status: "",
        date_from: null,
        date_to: null,
        shipping_from: null,
        shipping_to: null,
        zona: "",
      },
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      info_page:{
        routeParentName: null,
        nameSeccion: null,
      },
      zonas: [],
      statusPayment: [
        {
          value: 0,
          text: "Pendiente",
          class: "bg-soft-warning",
        },
        {
          value: 1,
          text: "Pagado",
          class: "bg-soft-success",
        },
        {
          value: 2  ,
          text: "Cancelado",
          class: "bg-soft-danger",
        }
      ],
      statusShipping: [
        {
          value: 0,
          text: "Pendiente",
          class: "bg-soft-warning",
        },
        {
          value: 1,
          text: "En ruta",
          class: "bg-soft-info",
        },
        {
          value: 2,
          text: "Entregado",
          class: "bg-soft-success",
        },
        {
          value: 3,
          text: "Cancelado",
          class: "bg-soft-danger",
        }
      ],
      fields: [  
        {
          key: "cliente.full_name",
          label: "Cliente",
          sortable: true,
        },
        {
          key: "horario.text",
          label: "Horario",
          sortable: true,
        },
        {
          key: "date",
          label: "Fecha",
          sortable: true,
        },
       
        {
          key: "total",
          sortable: true,
        },
        {
          key: "payment_status",
          label: "Estado de Pago",
          sortable: true,
        },
        
        {
          key: "shipping_status",
          label: "Estado de Envio",
          sortable: true,
        },
        {
          key: "zona.name",
          label: "Zona",
          sortable: true,
        },
        {
          key: "action",
          label: "Acciones",
        },
        "action",
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.invoicelist.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  created(){

    this.initPage();
    this.chargeItems();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    initPage(){
      this.info_page.routeParentName = routeParentName;
      this.info_page.nameSeccion = nameSeccion;
    },
    async chargeItems(){
        await this.aditionalServices();


        itemService.getList().then(response=>{
          this.invoicelist = response.data.object_data;
        });
         

    },
    
    async aditionalServices(){
        let response = await zonaService.getList();
        response =  response.data.object_data;
        this.zonas = response;
      },
    formatDate(date, format = "short"){
      return helper.formatDate(date, format);
    },
    formatMoney(money){
      return helper.formatMoney(money);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    
    applyFilter(){
      let params = {...this.filter};
      if(params.date_from!==null){
        params.date_from = moment(params.date_from, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.date_to!==null){        
        params.date_to = moment(params.date_to, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.shipping_from!==null){
        params.shipping_from = moment(params.shipping_from, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }
      if(params.shipping_to!==null){        
        params.shipping_to = moment(params.shipping_to, 'DD-MMM-YYYY').format('YYYY-MM-DD');
      }


      itemService.getList(params).then(response=>{
          this.invoicelist = response.data.object_data;
        });

    },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-4">
        <div>
          <button type="button" class="btn btn-success mb-3"  @click="routeGo(info_page.routeParentName+'/agregar')">
            <i class="mdi mdi-plus me-1"></i> Agregar {{info_page.nameSeccion}}
          </button>
        </div>
      </div>
      <div class="col-md-3">
        <div
              class="input-daterange input-group"
              data-provide="datepicker"
              data-date-format="dd M, yyyy"
              data-date-autoclose="true"
            >
             <date-picker
                          v-model="filter.date_from"
                            :first-day-of-week="1"
                            format="DD-MMM-YYYY"
                            lang="es"
                          class="form-control text-left date-custom bx-left"
                          placeholder="Compra desde"
                          ></date-picker>
               <date-picker
                          v-model="filter.from_to"
                            :first-day-of-week="1"
                            format="DD-MMM-YYYY"
                            lang="es"
                          class="form-control text-left  date-custom bx-right"
                          placeholder="Compra hasta"
                          ></date-picker>
           
            </div>
      </div>
      <div class="col-md-3">
        <div class="float-end">
          <div class="form-inline mb-3">
            <div
              class="input-daterange input-group"
              data-provide="datepicker"
              data-date-format="dd M, yyyy"
              data-date-autoclose="true"
            >
             <date-picker
                          v-model="filter.shipping_from"
                            :first-day-of-week="1"
                            format="DD-MMM-YYYY"
                            lang="es"
                          class="form-control text-left date-custom bx-left"
                          placeholder="Enviado desde"
                          ></date-picker>
               <date-picker
                          v-model="filter.shipping_to"
                            :first-day-of-week="1"
                            format="DD-MMM-YYYY"
                            lang="es"
                          class="form-control text-left  date-custom bx-right"
                          placeholder="Enviado hasta"
                          ></date-picker>
           
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="input-group-append float-end">
                <button type="button" class="btn btn-primary" @click.prevent="applyFilter()">
                  Filtrar <i class="mdi mdi-filter-variant"></i>
                </button>
              </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-5">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Mostrar&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-2">
        <div class="text-md-end">
          <select   class="form-control select2" v-model="filter.payment_status">
            <option value="">Estado de pago </option>
            <option  :value="element.value" v-for="element in statusPayment" :key="element.value" v-text="element.text"></option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 col-md-2">
        <div class="text-md-end">
          <select   class="form-control select2" v-model="filter.zona">
            <option value="">Zonas</option>
            <option  :value="element.id" v-for="element in zonas" :key="element.id" v-text="element.name"></option>
          </select>
        </div>
      </div>
      <!-- End search -->
      <div class="col-sm-12 col-md-3">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Buscar:
            <b-form-input
              v-model="filter.name"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="invoicelist"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(check)="data">
          <div class="custom-control custom-checkbox text-center">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`contacusercheck${data.item.id}`"
            />
            <label
              class="custom-control-label"
              :for="`contacusercheck${data.item.id}`"
            ></label>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.item.id }}
          </a>
        </template>
       
        <template v-slot:cell(total)="data">
            {{ formatMoney(data.item.total) }}
        </template> 
        <template v-slot:cell(date)="data">
            {{ formatDate(data.item.date) }}
        </template>

        <template v-slot:cell(payment_status)="data">
          <div
            class="badge rounded-pill  font-size-12"
            :class="statusPayment[data.item.payment_status].class"
          >
            {{ statusPayment[data.item.payment_status].text }}
          </div>
        </template>
        <template v-slot:cell(shipping_status)="data">
          <div
            class="badge rounded-pill  font-size-12"
            :class="statusShipping[data.item.shipping_status].class"
          >
            {{ statusShipping[data.item.shipping_status].text }}
          </div>
        </template>
  
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a
                class="px-2 text-primary"
                v-b-tooltip.hover
                 @click="routeGo('/pedidos/editar/'+data.item.id)"
                title="Edit"
              >
                <i class="uil uil-pen font-size-18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a
                 @click="routeGo('/pedidos/ver/'+data.item.id)"
                class="px-2 text-primary"
                v-b-tooltip.hover
                title="Ver detalles"
              >
                <i class="uil uil-eye font-size-18"></i>
              </a>
            </li>
          </ul>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>
